.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0% !important;
  padding: 0rem 1rem;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.login-form-row .input {
  margin-top: 5px !important;
  padding: 7.5px !important;
  font-size: 12px !important;
}

.login-form-row .phone-input {
  margin-top: 5px !important;
  height: 31px !important;
}

.signup-form-row > div > input {
  font-size: 12px !important;
}

.login-form-row .PhoneInputInput {
  height: 31px !important;
}

.login-form-row a {
  margin-top: -2.5px !important;
  font-size: 10px !important;
}

.login-form-row .navbar-guest-home {
  height: 100px;
}

.login-signup-holder .login-button {
  margin-top: 5px !important;
  height: 31px !important;
  font-size: 13px !important;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-container {
  height: 60px;
  background-color: #0d1333;
}

.left-side-navbar {
  display: flex;
  align-items: center;
  width: 40%;
}

.hamburger-image {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.right-side-navbar {
  display: flex;
  justify-content: space-between;
  width: 50%;
  height: 100%;
  margin: 0%;
  align-items: center;
}

.home-link-item {
  display: none;
}

.logo-container {
  display: inline-block;
  margin: 0 15px;
  top: 50%;
}

.explore-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
  background: linear-gradient(to right, #24ff2d, #24ffff);
  border-radius: 7px;
  text-decoration: none !important;
  padding: 10px 60px;
  margin-left: 25%;
}

.explore-link > * {
  margin: 0 4px;
}

.explore-link > h1 {
  text-decoration: none !important;
  font-size: 17px;
  color: white !important;
  font-weight: bold;
}

.link-bear {
  margin: 0px !important;
  padding: 0px !important;
}

.link-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0% !important;
  height: 100%;
  cursor: pointer;
  text-decoration: none !important;
}

.link-item > * {
  margin: 0 4px;
}

.link-item > h2 {
  text-decoration: none !important;
  font-size: 15px;
  color: white;
}

.nav-link {
  padding: 0px 5px;
  border-bottom: 2px solid transparent;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
}

.nav-link:hover > h2 {
  color: var(--blueHover) !important;
}


.nav-link:hover {
  border-bottom: 2px solid var(--blueHover);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  /* position: relative; */
}

/* .nav-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--blueHover);
  transition: 0.5s;
  transition: width 0.5s;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.nav-link:hover::after {
  width: 100%; /* Make the background span the full width on hover */


.navbar-select {
  width: 70%;
  font-size: 14px !important;
  cursor: pointer !important;
}

.navbar-select > div {
  border-radius: 7px !important;
}

.navbar-select > div:focus {
  border: none !important;
  outline: none !important;
}

.select-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  width: 25%;
  margin: 0%;
  text-decoration: none !important;
}

.select-item > .link-bear {
  width: 10%;
}

.select-item > .link-bear > .avatar-container {
  margin-top: -10px !important;
  width: 35px;
  height: 45px;
}

.select-item > * {
  margin: 0;
  margin-left: 4px;
}

.usernamebox {
  font-size: 12px;
  padding: 2% 5%;
  height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 70%;
  border-radius: 15px;
  height: 40px;
  background-color: white;
  color: rgb(28, 28, 28);
  border-width: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
}

.usernamebox:focus {
  outline: none;
}

.login {
  color: white;
  background-color: transparent;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px dashed white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login:hover {
  cursor: pointer;
  opacity: 0.75;
}

.signup {
  color: black;
  font-weight: bold;
  background-color: var(--green);
  padding-left: 10px;
  padding-right: 10px;
  border: 0px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup:hover {
  cursor: pointer;
  background-color: var(--greenHover);
}

.login-signup-holder {
  width: 50%;
}

.login-signup-holder > div > a {
  text-decoration: none !important;
}

.open-login-button {
  display: none;
  border: none;
  background-color: #fff;
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 15px;
  color: #000;
  cursor: pointer;
}

.open-login-button:hover {
  background-color: #ededed;
}

@media screen and (max-width: 1000px) {
  .navbar {
    padding: 1rem 2px !important;
    gap: 12px;
    flex-wrap: wrap;
  }

  .navbar-container {
    height: auto;
  }

  .login-signup-holder {
    width: 30%;
    display: flex;
    justify-content: end;
    flex-direction: row;
    margin-right: 20px;
  }

  .login-form-row > div {
    width: 100%;
  }

  .login-form-row > input,
  .login-form-row > div > input {
    width: 100% !important;
  }

  .login-button-holder {
    width: 100%;
  }
  .login-button-holder > button {
    width: 90%;
    margin: auto;
  }

  .login-form {
    width: 100%;
  }

  .login-form-row {
    width: 90% !important;
    margin: 0 auto !important;
  }

  .login-form {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .show-login-form {
    display: flex !important;
  }

  .open-login-button {
    display: inline;
  }

  .logo-container-link {
    display: none;
  }

  .left-side-navbar {
    width: 90%;
    margin-left: 5px;
    gap: 1px;
    align-items: center;
    justify-content: space-between;
  }

  .left-side-navbar.small-screen-authpage {
    width: 30% !important;
  }
  .right-side-navbar {
    width: 30%;
    justify-content: center;
    align-items: center;
  }

  .right-side-navbar > a {
    display: none;
  }

  .explore-link {
    width: 100%;
    padding: 12px 35px;
    display: flex;
    margin-left: 30px !important;
  }

  .explore-link > h2 {
    font-size: 13px;
  }

  .link-item {
    width: 30%;
    flex-direction: column;
    font-size: 12px;
  }

  .link-item > h2 {
    margin-top: 3%;
    font-size: 13px;
  }

  .select-item {
    width: 100%;
    align-items: center;
    gap: 0px;
    justify-content: flex-end;
  }

  .select-item > .link-bear > .avatar-container {
    display: none;
  }

  .home-link-item {
    display: flex !important;
    margin: 0% !important;
    width: 20%;
    text-decoration: none !important;
  }

  .home-link-item > * {
    margin: 0 4px;
  }
}

@media screen and (max-width:576px){
  .explore-link{
    padding: 12px 20px;
    margin-left: 20px !important;
    width: 100%;
  }
  .right-side-navbar{
    width: 40%;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1300px) {
  .left-side-navbar {
    width: 35%;
    gap: 1px;
    align-items: center;
    justify-content: space-between;
  }

  .right-side-navbar {
    width: 60%;
    justify-content: space-between;
    align-items: center;
  }

  .explore-link {
    width: 50%;
    margin-left: 20px;
  }

  .explore-link > h2 {
    font-size: 13px;
  }

  .link-item {
    width: 25%;
    font-size: 12px;
    margin: 0% 20px !important;
  }

  .link-item > h2 {
    margin-top: 3%;
    font-size: 13px;
  }

  .select-item {
    width: 50%;
  }

  .usernamebox {
    font-size: 12px;
    padding: 2% 5%;
    height: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media screen and (max-width: 1001px) {
  .navbar-container {
    flex-direction: column;
  }
  .open-login-button {
    display: none;
  }
  .open-login-button.small-screen {
    display: inline !important;
    margin-bottom: 10px;
  }
}
.open-login-button.small-screen {
  display: none;
}
