.sidebar-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 20%;
    transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
    background-color: #0d1333;
    padding-top: 60px;
    z-index: 1;
  }

  .sidebar-container>h2{
    font-size: 15px;
    margin-left: 10px;
  }
.sidebar-list-divider{
    width: 100%;
    margin-left: 1%;
    height: 2px;
    border-radius: 12px;
    background-color: white;
    margin-bottom: 20px;
  }
  .sidebar-links{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  .sidebar-link-item{
    margin: 2px 0px 2px 0px;
    padding-right: 60px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-decoration: none !important;
    color: #fff !important;
    font-size: 17px !important;
    cursor: pointer !important;
    transition: background-color 0.1s ease;
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .sidebar-link-item:hover{
    color: #fff;
    background-color: var(--blueHover);
  }
  
  .sidebar-link-item.active {
    color: var(--blue) !important;
    font-weight: bold;
    border-right: 3px solid var(--blueHover);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
 
  .sidebar-link-item.active:hover {
    color: #fff !important;
  }

  .sidebar-container.closed {
    transform: translateX(-100%);
  }
  
  @media (max-width: 768px) {
    .sidebar-container {
      width: 100%;
      margin-top: 50px;
      height: 100vh;
      transform: translateX(-100%);
      z-index: 999;
    }
  
    .sidebar-container.open {
      transform: translateX(0);
    }
  
    .inner-app-content {
      margin-left: 0;
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 1300px) {
    .sidebar-container{
      margin-top: 10px;
    }
  }

.sidebar-footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid lightgray;
  }
  .sidebar-footer p{
    color:lightgray;
    font-size: 12px;
    margin-top: 15px;

}