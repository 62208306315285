.contactus-container {
    padding: 5px;
 }
.contactus-form{
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
}
.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
}
  
input,
select,
textarea {
  width: 100%;
  padding: 12px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
  
textarea {
  height: 100px;
  resize: vertical;
}

select:focus,textarea:focus{
  outline: none;
  border: 1px solid #00dd00;
}
  
.required {
  color: red;
  margin-left: 2px;
}

.contact-us-submit-btn {
  width: 30%;
  margin: auto;
  padding: 12px 35px;
  background-color: var(--green);
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  align-self: center;
  text-align: center;
}

.contact-us-submit-btn:hover {
  background-color: #06ba06;
}

@media screen and (max-width: 767px) {
  .contactus-form{
      width: 90%;
  }
  .contact-us-submit-btn{
      width: 50%;
  }
}