.friends-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.friends-container > div {
  width: 60%;
  display: flex;
  flex-direction: column;
  margin: 0% 0% !important;
}

.friends-container > div > h3 {
  font-size: 18px;
  font-weight: bold;
}

.pending-friends > div {
  background-color: var(--green);
}

.pending-friends-username {
  color: #fff !important;
}

.friend-card > span,
.history-card > span {
  font-size: 15px;
  color: #000;
  margin-left: 5px;
  margin-top: 5px;
}

.send-friend-request {
  display: flex;
  margin-bottom: 5px;
}

.send-friend-request input {
  flex: 1;
  padding: 12px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input:focus {
  outline: none;
  border: 1px solid #00dd00;
}

.send-friend-request button {
  margin-left: 10px;
  padding: 8px 30px;
  background-color: var(--green);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.send-friend-request button:hover {
  background-color: var(--greenHover);
}
.history-card-container:first-child {
  margin-top: 20px;
}

.history-card-container {
  display: flex;
  background-color: #f0f0f0;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 0px;
  align-items: center;
  border-radius: 4px;
  transition: transform 0.3s ease-in-out;
}

.history-card-container > div {
  width: 50%;
}

.history-time > p {
  color: #000;
  margin: 0%;
  font-size: 15px;
}

.history-time {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
}

.history-avatar-username-container {
  display: flex;
}

.friend-card {
  display: flex;
  align-items: center;
  padding: 3px 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  justify-content: space-between;
  position: relative;
  transition: transform 0.3s ease-in-out, z-index 0.3s ease-in-out;
}
.friend-card span{
  color:#202336;
}
.avatar-container-name{
  display: flex;
  align-items: center;
}
.friend-card .actions{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  cursor: pointer !important;
}

.friend-card .actions .message-icon, .friend-card .actions .more-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  padding: 10% 10%;
  border-radius: 5px;
  background-color: var(--blue);
  border: none;
  cursor: pointer !important;
  transition: background-color 0.3s ease;
}
.friend-card .actions .more-icon{
  position: relative;
}
.dropdown-menu {
  position: absolute;
  top: 35px;
  right: 0;
  background-color: #202336;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 120px; 
  z-index: 20;
  
}
.dropdown-menu::after{
  content: '';
  position: absolute;
  top: -4px;
  right: 10px;
  border: 5px solid transparent;
  border-bottom-color: #202336;
  border-bottom-color: #202336;
  border-top: 0;
}


.dropdown-menu button {
  background: none;
  border: none;
  padding: 8px 12px;
  text-align: left;
  cursor: pointer;
  width: 100%;
  color: #fff;
}

.dropdown-menu button:hover {
  background-color: #05c3d8;
  z-index: 10;
}

.history-card {
  display: flex;
  align-items: center;
  padding: 10px;
}

.pending-friend-card {
  background-color: #f0f0f0;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
}

.friend-card-username-group {
  display: flex;
  align-items: center;
  padding: 10px;
}

.pending-friend-card:hover {
  transform: scale(1.012);
}
.friend-card:hover {
  transform: scale(1.012);
  z-index: 10; /* Bring the hovered card to the front */
}

.history-card-container:hover {
  transform: scale(1.012);
}

.friend-card img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.history-card img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.friend-actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  cursor: pointer !important;
}

.friend-actions button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  padding: 10% 10%;
  border-radius: 5px;
  background-color: var(--blue);
  border: none;
  cursor: pointer !important;
  transition: background-color 0.3s ease;
}

.friend-actions button:hover {
  background-color: var(--blueHover);
}

.friend-actions button img {
  width: 20px;
  margin: auto;
  cursor: pointer !important;
  height: 20px;
}

.no-friends {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 100px;
}

.no-friends h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #4d5583;
}

.no-friends p {
  margin-bottom: 10px;
  color: #4d5583;
}

.no-friends img {
  max-width: 200px;
  margin-bottom: 20px;
}

.avatar-container {
  position: relative;
  width: 40px;
  height: 50px;
}

.avatar-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-user-drag: none;
}

.show-more-friends-button {
  margin: auto;
  cursor: pointer;
  border-radius: 4px;
  padding: 13px 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  color: white;
  background-color: var(--green);
  border: none;
}

.show-more-friends-button:hover {
  background-color: var(--greenHover);
}

@media (max-width: 768px) {
  .friends-container > div {
    width: 95%;
  }
}

.user-status {
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
  border-radius: 50%;
  right: 0;
}
.user-status.offline {
  background: transparent;
}
.user-status.online {
  background-color: green;
}
