.wardrobe-container {
    display: flex;
    padding-top: 1%;
    flex-direction: column;
  }
  
  .wardrobe-item,
  .market-item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.75rem;
  }
  
  .item-name {
    font-weight: bold;
  }
  
  .avatar-preview {
    width: 30%;
    height: 150px;
    position: relative;
  }
  
  .avatar-layers img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .inventory-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 60px;
  }
  
  .inventory-category {
    width: 70%;
    margin-bottom: 5px;
    margin-left: 13%;
  }

  .inventory-category h2 {
    font-size: 16px;
    font-weight: bold;
  }
  
  .item-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
  }

  .no-items{
    padding-left: 1%;
  }

  .no-items p{
    color: #4D5583;
  }

  .inventory-items{
    display: flex;
    padding-left: 1%;
  }

  .inventory-items>div{
    margin-right: 2%;
  }

  .equip-item{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    position: absolute;
    display: none;
  }
  .equip-item>p{
    font-size: 24px;
    color: #fff;
    font-weight: bolder;
    text-transform: uppercase;
  }

  .goto-item{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    position: absolute;
    display: none;
  }
  .goto-item>p{
    text-align: center;
    color: #fff;
    font-weight: bolder;
    text-transform: uppercase;
  }

  .inventory-item:hover .equip-item{
    display: flex;
  }

  .item-thumbnail:hover .goto-item{
    display: flex;
  }

  .inventory-item-holder{
    display: flex;
    flex-direction: column;
    width: 100px;
    align-items: center;
    cursor: pointer;
  }
  .inventory-item-holder>p{
    font-size: 15px;
    margin: 0%;
  }
  .inventory-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    height: 90px;
    background-color: var(--blue);
    position: relative;
    cursor: pointer;
  }
  
  .inventory-category-line-segment {
    width: 80%;
    margin-left: 1%;
    height: 2px;
    border-radius: 12px;
    background-color: white;
    margin-top: 2px;
  }

  .inventory-item img {
    max-width: 90%;
    max-height: 90%;
  }
  
  .clear-items-holder{
    display: flex;
    flex-direction: column;
    width: 100px;
    align-items: center;
  }
  .clear-items-holder>p{
    font-size: 15px;
    margin: 0%;
  }

  .clear-items{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 4px;
    background-color: var(--blue);
    cursor: pointer;
    height: 90px;
  }

  .clear-items img{
    width: 80%;
    height: 80%;
  }
  .finish-button-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .finish-button {
    padding: 1% 2%;
    background-color: var(--green);
    margin: 24px 0;
    color: #fff;
    width: 20%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;    
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .finish-button:hover {
    background-color: var(--greenHover);
  }

  .avatar-preview-holder{
    display: flex;
    margin: auto;
    width: 60%;
  }
  .sidebar{
    display:flex;
    width: 70%;
    flex-wrap: wrap;
  }
  .sidebar-item{
    display: flex;
    flex-direction: column;
    width: 60px;
    height: 70px !important;
    margin: 2% 2%;
    box-sizing: border-box;
    cursor: pointer;
  }
  .item-name{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    height: 20%;
  }
  .item-thumbnail{
    margin-top: 5%;
    background-color: var(--green);
    border-radius: 7px;
    display: flex;
    width: 100%;
    height: 80%;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .item-thumbnail>img{
    width: 90%;
    height: 90%;
    object-fit: contain;
  }

  @media (max-width: 768px) {
    .inventory-category{
      width: 100%;
    }
    .avatar-preview-holder{
      width: 100%;
    }
  }