
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 100%;
    margin: auto;
    border-bottom: 1px solid #333;
  }

  .message-user-profile{
    height: 100%;
    display: flex;
    width: 100%;
  }

  .message-avatar-container {
    position: relative;
    width: 40px;
    margin-top: -2px;
    height: 40px;
    flex-shrink: 0;
  }

  .sent-profile-avatar{
    justify-content: flex-end;
  }
  
  .message-avatar-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .user-info {
    display: flex;
    align-items: center;
  }
  .user-info>.username{
    color: white;
    font-weight: bold;
    font-size: 14px;
  }

  .message-container {
    flex-grow: 1;
    overflow-y: auto;
    width: 100%;
    margin: 10px auto;
    padding: 5px;
   }

   .message {
    display: flex;
    flex-direction: column;
    max-width: 70%;
    margin-bottom: 15px;
    position: relative;
  }

  .sent{
    margin-left: auto;
  }

  .message-sent {
    margin-left: auto;
    flex-direction: row-reverse;
  }

  .message-received,
  .message-sent {
    display: flex;
    align-items: flex-end;
   }

  .message-received{
    max-width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-left: 25px;
  }

  .message-text {
    padding: 10px 15px;
    border-radius: 14px;
    font-size: 15px;
    position: relative;
    word-wrap: break-word;
    margin-right: 25px;
    margin-bottom: 10px; 
  }
  
  .message-received .message-text {
    background-color: var(--green);
    color: white;
  }
  
  .message-sent .message-text {
    background-color: var(--blue);
    color: white;
  }
  
  .message-input-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #333;
  }
  
  .message-input-container > textarea {
    flex-grow: 1;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #fff;
    color: #000;
    height: 50px;
    font-family: inherit;
    font-size: 15px;
  }

  .message-input-container > textarea:focus{
    outline: none;
    border: 1px solid #04d1d1;
  }
  

  .send-message-count-btn{
    width: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
  }

  .send-message-count-btn{
    margin-top: 2px;
  }

  .send-message-count-btn>p{
    font-size: 12px;
    text-align: center;
    margin: 0%;
    margin-bottom: 5px;

  }
  .send-message-count-btn span{
    font-weight: bold;
  }

  .send-button {
    background-color: var(--blue);
    border: none;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    cursor: pointer;
    color: #fff;
    font-size: 17px;
  }

  .send-button:hover{
    background-color: var(--blueHover);
  }
  
  .arrow-up {
    color: white;
  }
  
  .foreign-user-avatar-container{
    position: relative;
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
  }

  .foreign-user-avatar-container img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .message-popup {
    background-color: #181826;
    width: 75%;
    height: 80%;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
  }

  .message-container::-webkit-scrollbar {
    width: 6px;
  }
  
  .message-container::-webkit-scrollbar-track {
    background: #181826;
  }
  
  .message-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
  }
  
  .message-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }


  .message-received .message-text::after {
    content: '';
    position: absolute;
    left: 8px;
    bottom: -8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-left-width: 10px;
    border-right-width: 10px;
    border-bottom-width: 20px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: var(--green);
    transform: rotate(97deg);
  }
  
  .message-sent .message-text::after {
    content: '';
    position: absolute;
    right: 0px;
    bottom: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-left-width: 10px;
    border-right-width: 10px;
    border-bottom-width: 20px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: var(--blue);
    transform: rotate(140deg);
  }

  .message-title{
    width: 50%;
    margin: auto;
    font-size: 15px;
    color: #ebebeb;
    opacity: 0.8;
    text-align: center;
    margin-bottom: 20px;
    font-weight: normal;
  }

  @media (max-width: 768px) {
    .message-popup {
        height: 70%;
        width: 90%;
      }
    .message {
        display: flex;
        flex-direction: column;
        max-width: 80%;
        margin-bottom: 15px;
        position: relative;
    }  
  }