.marketplace-container {
  margin: 1% auto;
  width: 100%;
  margin-top: 10px;
}
.marketplace-content {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 20px;
}
.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 10px 0;
  gap: 10px;
}
.search-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #0d1333;
  border-radius: 5px;
}
.search-holder input {
  width: 100%;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 16px;
  padding-left: 10px;
}

.search-holder input:focus {
  outline: none !important;
}

.search-holder input::placeholder {
  color: rgba(13, 19, 51, 0.62);
  font-size: 16px;
}

.search-holder .search-icon {
  border: none;
  color: white;
  padding: 8px 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 80px;
  border-left: 2px solid #4d5583;
}
.kaybit-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 8px 20px;
  gap: 5px;
  background-color: var(--green);
}
.kaybit-holder:hover {
  cursor: pointer;
}
.kaybit-holder p {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
.kaybit-holder .kaybit-icon {
  width: 20px;
  height: 20px;
}
.kaybit-holder .kaybit-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.points-display-holder {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  margin-bottom: 20px;
}

.market-sidebar {
  border-right: 1px solid #4d5583;
  padding: 0px 20px;
}
.market-sidebar > h2 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}
.market-sidebar .sort-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}

.market-sidebar .sort-options > button {
  background-color: transparent;
  border: none;
  color: lightgray;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  margin-left: 10px;
}
.market-sidebar .sort-options > button:hover {
  color: var(--green);
}
.market-sidebar .sort-options > button::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border: 1px solid lightgray;
  border-radius: 50%;
  transform: translate(-120%, 0%);
  margin: auto;
  top: 0;
  bottom: 0;
  margin-left: -5px;
}
.market-sidebar .sort-options > button:hover::before {
  border-color: var(--green);
}
.market-sidebar .sort-options > button.active {
  color: var(--green);
}
.market-sidebar .sort-options > button.active::before {
  border-color: var(--green);
  background-color: var(--green);
}

.points-display {
  width: 80%;
  margin: auto;
  display: flex;
  gap: 5px;
  justify-content: space-evenly;
  align-items: flex-end;
}

.points-display > p {
  font-size: 18px;
  margin: 10px 0;
}

.categories-container {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  overflow-x: auto;
}

.category-section {
  width: 100%;
  padding: 1% 2%;
  /* margin: auto; */
  margin-bottom: 20px;
  height: auto;
}

.category-title {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.item-grid {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  height: auto;
  position: relative;
}
.item-grid::-webkit-scrollbar {
  display: none;
}

.item-grid .controls {
  top: 50px !important;
  display: none;
}

.item-grid:hover .controls {
  display: inline;
}

.item-grid .controls--back {
  left: 0 !important;
}

.item-grid .controls--next {
  right: 0 !important;
}

.item-card-holder {
  display: flex;
  flex-direction: column;
  width: 140px;
  height: auto;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  flex: 0 0 auto;
  position: relative;
  justify-content: center;
  align-items: center;
}
.item-card-holder.search {
  width: 100px;
}

.item-card-holder:hover {
  transform: scale(1.05);
}

.item-card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  height: 120px;
  background-color: #4d5583;
  cursor: pointer;
  margin-bottom: 10px;
}

.shop-card {
  background-color: rgb(229, 229, 0) !important;
}

.item-card img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.item-card-holder > p {
  margin: 0% 10px;
  font-size: 14px;
}

.item-card-holder > h3 {
  margin: 0% 10px !important;
  font-size: 14px;
}

.no-items-message {
  text-align: center;
  font-size: 16px;
  color: #fff;
}
.popup-single-item-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 10px 0;
  gap: 10px;
}

.popup-overlay,
.kaybits-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content,
.kaybits-modal-content {
  background-color: #0d1333;
  padding: 3% 3%;
  border-radius: 5px;
  max-width: 500px;
  position: relative;
}
.kaybits-modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}
.kaybits-modal-header span {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  display: flex;
  gap: 10px;
}
.kaybits-modal-header .kaybits-coin {
  width: 20px;
  height: 20px;
}
.kaybits-modal-line {
  width: 100%;
  height: 1px;
  background-color: white;
  margin: 5px 0;
  display: block;
}
.kaybits-modal-title {
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
  margin-top: 10px;
}
.kaybits-modal-body {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}
.kaybits-modal-body span {
  font-size: 14px;
  color: #fff;
  margin-bottom: 20px;
}

.shop-popup {
  min-width: 200px !important;
  max-width: 700px !important;
}

.popup-content > p {
  margin: 1% 0% !important;
  font-size: 15px;
}

.popup-image-holder {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.popup-image {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  height: 170px;
  background-color: #4d5583;
  cursor: pointer;
  margin-bottom: 10px;
  width: 130px;
}
.popup-button-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  border: 2px dashed #4d5583;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.popup-item-details {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.popup-details-cost {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 5px 0;
}
.popup-details-cost span,
.popup-item-details span {
  font-size: 16px;
  font-weight: bold;
  color: #6872b1;
}

.shop-popup-image {
  background-color: rgb(229, 229, 0) !important;
}

.popup-image img {
  max-width: 95%;
  max-height: 95%;
  object-fit: contain;
}

.shop-buttons {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
}

.popup-button-name {
  margin-top: 5%;
  margin-left: 5%;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.popup-button-name > h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 50px;
}

.popup-content img {
  max-width: 100%;
  height: auto;
}

.open-checkout-button {
  background-color: var(--green) !important;
  border: none !important;
}

.open-cart-button:hover {
  background-color: var(--greenHover) !important;
}

.open-market-button {
  background-color: var(--blue) !important;
  border: none !important;
}
.open-market-button:hover {
  background-color: var(--blueHover) !important;
}

.open-cart-button {
  background-color: var(--green);
  border: 0px solid white;
  color: white;
  border-radius: 6px;
  padding: 9px 20px;
  height: auto;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  white-space: nowrap;
}

.checkout-button {
  border: 1px solid var(--green) !important;
}

.checkout-button:hover {
  border: 1px solid var(--greenHover) !important;
}

.open-cart-button > svg {
  width: 20px;
  height: 20px;
}
.open-cart-button > img {
  width: 20px;
  height: 20px;
}

.open-market-forward-icon {
  font-size: 30px !important;
  width: auto !important;
  height: auto !important;
}

.open-cart-button:hover {
  border: 0px solid var(--greenHover);
}

.purchase-button {
  background-color: var(--green);
  color: white;
  border: none;
  border-radius: 6px;
  padding: 9px 35px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
}

.purchase-button:hover {
  background-color: var(--greenHover);
}

.add-to-cart-button {
  border: 1px dashed white;
  background-color: transparent;
  color: white;
  border-radius: 6px;
  padding: 9px 35px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
}

.category-line-segment {
  width: 100%;
  margin-top: 1%;
  margin-left: 1%;
  height: 2px;
  border-radius: 12px;
  background-color: white;
}

.points-line-segment {
  width: 90%;
  margin: 0% auto;
  height: 2px;
  border-radius: 12px;
  background-color: white;
}

.close-icon-holder {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-color: rgba(232, 8, 8, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.close-icon-holder:hover {
  background-color: rgba(199, 4, 4, 0.5);
}

.close-icon {
  width: 90%;
  height: 90%;
  background-image: url("../images/x.png");
  background-size: cover;
}

.marketplace-avatar-container {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 10px auto;
}

.marketplace-avatar-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.purchase-info-popup {
  background-color: #202336;
  width: 45%;
  margin: auto;
  max-height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5px;
  padding-top: 30px;
}

.purchase-info-popup p {
  margin: 0% auto;
  width: 80%;
  margin-top: 30px !important;
  margin-bottom: 250px !important;
}

.cart-button-holder {
  position: relative;
  width: 60px;
  height: 40px;
  margin-bottom: 20px;
  cursor: pointer;
}

.cart-icon {
  height: 90%;
  width: 90%;
  margin-top: 10%;
}

.cart-items-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(215, 39, 39);
  position: absolute;
  top: 0;
  right: 0px;
  border-radius: 100%;
  padding: 2px 7px;
  font-size: 10px;
  color: #d4d4d4;
}

.cart-item-content {
  display: flex;
  justify-content: space-between;
  width: 80%;
  padding: 10px 5px 5px;
  color: #fff;
  align-items: center;
}

.cart-item-content > h2 {
  font-size: 15px;
  margin-left: 10px;
  width: 50%;
  font-weight: normal;
}

.cart-item-content > p {
  font-size: 14px;
  width: 20%;
}

.cart-item-image {
  background-color: rgb(229, 229, 0) !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 10%;
  border-radius: 4px;
  height: 60px;
}

.cart-item-image > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cart-item {
  transition: none !important;
}

.cart-item:hover {
  transform: none !important;
}

.cart-container-holder {
  gap: 5px !important;
  margin: 0% !important;
  height: 45vh;
}

.cart-popup {
  min-height: 50%;
  overflow-y: auto;
  justify-content: space-between !important;
  justify-content: flex-start !important;
}

.remove-cart-item-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  margin: auto 0;
  border-radius: 50%;
  background-color: var(--blue);
  border: none;
  cursor: pointer !important;
}

.remove-cart-item-button:hover {
  background-color: var(--blueHover);
}

.remove-cart-item-button img {
  width: 15px;
  margin: auto;
  height: 15px;
}

.checkout-cart-button-holder {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-grid {
  overflow-x: hidden;
}

.no-cart-items {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  width: 100%;
}

.no-cart-items > p {
  font-size: 17px;
  color: #4d5583;
  font-weight: bold;
}
.popup-details {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 5px 0;
}
.popup-icon-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 50%;
  margin-left: 5px;
}
.rc-slider-track,
.rc-slider-tracks {
  background-color: #4d5583 !important;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  box-shadow: 0 0 0 5px #fff !important;
}
.rc-slider-handle {
  border: 2px solid #ffffff !important;
}
@media (max-width: 850px) {
  .purchase-info-popup {
    width: 95%;
  }

  .points-display {
    width: 100% !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .category-section {
    width: 100%;
  }
  .points-line-segment {
    width: 100%;
  }

  .points-display-holder {
    width: 100%;
  }

  .shop-popup {
    max-width: 90% !important;
  }

  .item-card-holder {
    width: 120px;
  }

  .item-card {
    height: 100px;
  }

  .item-card-holder > p {
    font-size: 13px;
  }

  .item-card-holder > h3 {
    font-size: 14px;
  }

  .item-grid {
    overflow-x: auto;
  }

  .item-grid .controls {
    display: none !important;
  }
  .search-container {
    width: 90%;
    flex-direction: column;
  }
  .marketplace-content {
    grid-template-columns: 1fr !important;
    grid-template-rows: auto auto;
  }
  .market-sidebar {
    border-right: none;
  }
  .categories-holder .categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1300px) {
  .item-card-holder {
    width: 130px;
  }

  .item-card {
    height: 110px;
  }
}
