.home-page-container {
  padding: 5px;
  padding-bottom: 40px;
}

.app-description-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.app-description {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}

.app-description > img {
  width: 80%;
}

.app-description > h2 {
  margin-top: 50px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}
.carousel {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 8px;
  width: 50%;
}

.slider-content {
  width: 100%;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: scale(1.05);
}

.slide.active {
  opacity: 1;
  transform: scale(1);
}

.slide.transitioning {
  opacity: 0;
  transform: scale(0.95);
}

.slide video,
.slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.slide img {
  cursor: pointer;
  border-radius: 5px;
}

.slide video {
  background-color: black;
}

.youtube-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.youtube-player iframe {
  width: 100%;
  height: 100%;
}

.controls {
  position: absolute;
  top: 50%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  transition: background-color 0.3s ease;
}

.controls:hover {
  background: rgba(0, 0, 0, 0.7);
}

.controls--back {
  left: 10px;
}

.controls--back > svg {
  transform: rotateY(180deg);
}

.controls--next {
  right: 10px;
}

.slider-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.dot:hover {
  transform: scale(1.2);
}

.dot.active {
  background-color: white;
  transform: scale(1.2);
}

.bear-images-holder {
  width: 60%;
  margin: 0px auto !important;
  display: flex;
  justify-content: space-evenly;
}
.bear-images-holder > img {
  height: 100px;
  width: 120px;
  object-fit: contain;
}
.download-kayber {
  width: 50%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 20px;
}

.download-kayber > h2 {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.download-kayber-options {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.download-kayber-options > a {
  text-decoration: none !important;
}

.download-kayber-options > a > img {
  width: 160px;
  height: 50px;
  transition: transform 0.3s ease-in-out;
}

.download-kayber-options > a > img:hover {
  transform: scale(1.05);
}

.android-download-explore {
  font-size: 16px;
  text-align: center;
  margin: 10px 0px;
}
.android-downloader-header {
  margin-top: 60px;
}
.android-download-buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px;
  gap: 5px;
}
.android-download-buttons-container > button {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: transparent;
  border: 1px dashed #fff;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.download-android-button > img {
  width: 150px;
  cursor: pointer;
}

.btn-error{
  background-color: transparent;
  color: white;
  border: 1px solid red;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
  .carousel {
    width: 100%;
  }
  .app-description {
    width: 100%;
  }
  .bear-images-holder {
    width: 100%;
    margin-top: 20px;
  }
  .bear-images-holder > img {
    height: 40px;
    width: 40px;
  }
  .download-kayber {
    width: 100%;
  }
}

/*New Homepage*/
.home-page-holder {
  display: flex;
  width: 100%;
  margin: 30px auto 50px;
  max-width: 1200px;
}

.left-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.feature-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 0px 20px;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 2rem;
  width: 100%;
}

.top-item > p {
  font-size: 40px !important;
  font-weight: 700 !important;
  /* background: linear-gradient(80deg, var(--green), #fff, var(--blue)); */
  /* background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  display: inline-block;
  animation: gradientMove 10s infinite;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.feature-item > svg {
  flex-shrink: 0;
  color: #6872b1;
  font-size: 40px;
}

.feature-item > p {
  color: lightgray;
  flex-grow: 1;
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

.feature-item > p > span {
  font-weight: bold;
  color: #fff;
}

.feature-item img {
  width: 48px;
  height: 48px;
}

.right-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 5px 20px;
}

.home-signup-container > h2 {
  font-size: 20px;
}

.user-info-container {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.user-image img {
  width: 80px;
  height: 80px;
}

.user-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.user-details input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.sign-up-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 300px;
}

.sign-up-form label {
  font-weight: bold;
}

.sign-up-form input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.password-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.signup-button {
  background-color: #4caf50;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.feature-list-mobile-view {
  display: none;
}

.feature-item {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 1.2s ease-in-out, transform 1.2s ease-in-out;
}

.feature-item.animate-in {
  opacity: 1;
  transform: translateY(0);
}

.home-page-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.apple-download-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #000;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid lightgray;
}
.apple-download-button-container img {
  width: 35px;
}
.apple-download-button-container p {
  color: white;
  font-size: 20px;
}


.download-android-button {
  text-decoration: none;
}

.signup-other-btn {
  color: white;
  background-color: transparent;
  border: 1px dashed white;
  transition: background-color 0.3s ease-in-out;
  display: grid;
  place-items: center;
  grid-template-columns: 40px 1fr;
  opacity: 0;
  transform: translateY(10px);
}
.signup-other-btn.fade-in {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1.2s ease-in-out, transform 1.2s ease-in-out;
}

.apple-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  background-color:#FFFFFF;
  border: 1px solid transparent;
  -webkit-border-radius: 4px;
  border-radius: 4px;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  color:#000000;

}
.google-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  background-color:#FFFFFF;
  border: 1px inside #747775;
  cursor: pointer;
  color:#1f1f1f;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.facebook-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  background-color:rgb(26, 119, 242);
  border: 0px;
  cursor: pointer;
  color:#FFFFFF;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* font-weight: bold; */
}
.google-btn:hover{
  background-color: #ffff;
  color:#000 !important; 
}
.facebook-btn:hover{
  background-color: rgb(26, 119, 242);
  color:#FFFFFF !important; 
}



/* .signup-other-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 0.3s ease-in-out;
} */
.apple-btn:hover{
  background-color: #ffff;
  color:#000 !important; 
}
.row .col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.row .col .back-button {
  color: white;
  background-color: red;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.signup-option-container {
  width: 100%;
  
}

.signup-option-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.signup-option-divider {
  position: relative;
  color: lightgray;
  width: 60%;
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
}
.signup-option-divider::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 55%;
  height: 1px;
  background-color: lightgray;
  transform: translateY(-50%);
}

.login-option{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  order: 4;
  text-decoration:underline;
  cursor:pointer;
  color:var(--green);
}
.signup-option-divider::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 55%;
  right: 0;
  height: 1px;
  background-color: lightgray;
  transform: translateY(-50%);
}
.pulsate-btn {
  animation: pulsate 3s infinite;
}
@keyframes pulsate {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(76, 175, 80, 0.8);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(76, 175, 80, 0.6);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(76, 175, 80, 0.8);
  }
}

.signup-form {
  opacity: 0;
  transform: translateY(10px);
}


.form-transition {
  opacity: 1;
  transform: translateY(0);
  transition: opacity .9s ease-in-out, transform .9s ease-in-out;
}


@media only screen and (max-width: 768px) {
  .home-page-holder {
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
  }
  .feature-item {
    display: none;
  }

  .feature-list {
    padding: 0 10px;
  }

  .right-panel {
    padding: 5px 10px;
  }

  .top-item > p {
    font-size: 30px !important;
  }
  .top-item {
    margin-bottom: 20px;
  }

  .signup-text {
    font-size: 25px;
  }

  .signup-dob-select {
    width: 100%;
  }
  .loginpagebutton {
    width: 100%;
    font-size: 15px;
  }
  .feature-list-mobile-view {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  .feature-list-mobile-view > .feature-item {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }

  .feature-item > p {
    font-size: 15px;
  }

  .feature-item > svg {
    font-size: 35px;
  }
  .signup-option-divider{
    width: 100%;
  }
  .apple-download-button-container p{
    font-size:16px;
    text-wrap:nowrap;
  }
}
