.blog-holder{
    display: flex;
    flex-direction: column;
    background: linear-gradient(90deg, #24ff2d, #24ffff);
    min-height: 100vh;
}

.blog-title-holder{
    /* background: linear-gradient(45deg, #24ff2d, #24ffff); */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    position: relative;
}

.blog-title{
    background-color: #000;
    color:#fff;
    padding: 10px 30px;
    font-size: 25px;
    margin-bottom: 20px;
}

.blog-date{
    background-color: #000;
    padding: 5px 20px;
    margin: 0%;
    font-size: 14px;
    position: absolute;
    bottom: -10px;
    z-index: 3;
}

.blog-body{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    gap: 30px;
    padding: 5px 30px;
    border-left: 2px solid #000;
    border-right: 2px solid #000;
    background-color: #202336;
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
}

/* .blog-body::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /background: linear-gradient(45deg, #24ff2d, #24ffff);
    z-index: -1;
} */

.blog-item{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:5px;
    cursor: pointer;
}
.blog-body-header{
    font-size: 20px;
    color: #fff;

}
.blog-body-description{
    font-size: 16px;
    color: #fff;
}
.blog-body-image{
    object-fit: contain;
    width: 80%;
    margin: auto;
    border-radius: 10px;
}

.blog-posts-holder{
    display: flex;
    flex-direction: column;
}

.blog-posts-title{
    text-align: center;
    font-size: 25px;
    margin: 10px 0px;
}

.blog-posts-body{
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 70%;
    margin: auto;
}
.blog-post-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.blog-post-item:not(:last-child){
    border-bottom: 1px solid #ccc;
}
.blog-post-item:last-child{
    margin-bottom: 10px;
}

.blog-post-body-image{
    width: 90px;
    height: 80px;
    margin: 10px 0px;
    object-fit: cover;
}
.blog-post-body-header{
    width: 85%;
    justify-content: space-between;
    display: flex;
    gap: 5px;
    font-weight: normal;
    font-size: 17px;
}
.blog-post-body-title{
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    cursor: pointer;
    margin: 0%;
}

.blog-post-body-date{
    color: #ccc;
    font-size: 15px;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    gap: 10px;
    list-style: none;
    padding: 0;
}
  
.pagination>li>a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    color: #fff !important;
    background-color: var(--blue);
    border: 1px solid var(--blue);
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    overflow: hidden;
}
.pagination>li>a:hover {
    background-color: #0A6AC9;
    border: 1px solid #0A6AC9;
}
.pagination-active>a {
    background-color: #0A6AC9 !important;
    border: 1px solid #044b91 !important;
    color: white !important;
}
  
.pagination-disabled>a {
    pointer-events: none;
    border: #ccc;
    cursor: not-allowed;
    opacity: 0.5;
    color: #ccc !important;  
}

@media(max-width: 900px){
    .blog-body{
        width: 100%;
    }
    .blog-post-body-image{
        width: 60px;
        height: 50px;
    }

    .blog-posts-body{
        width: 95%;
    }
    .blog-post-body-header{
        width: 80%;
        flex-direction: column;
    }
}