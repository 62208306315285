.footer {
    background-color: #060912;
    color: white;
    padding: 15px 0%;
    font-size: 14px;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    flex-wrap: wrap;
    gap: 5px;
}

.footer-section{
    width: 30%;
}

.logo img {
    height: 40px;
    object-fit: contain;
    margin-top: 20px;
}

.error-message {
    color: red;
    font-size: 13px !important;
    margin-top: 5px !important;
}

.newsletter p {
    font-size: 16px;
    margin-bottom: 10px;
}

.newsletter form {
    display: flex;
}

.newsletter input {
    padding: 13px 8px;
    border: none;
    border-radius: 5px;
    border: 1px solid #fff;
    width: 200px;
}

.newsletter input:focus{
    border: 1px solid #06d5d5;
}

.newsletter button {
    padding: 12px 20px;
    border: none;
    background-color:var(--blue);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}
.newsletter button:hover{
    background-color: var(--blueHover);
}

.footer h5 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
}

.footer ul {
    list-style: none;
    padding: 0;
    text-align: center;
}

.footer ul li {
    margin-bottom: 8px;
}

.footer a {
    color: lightgray;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .footer{
        padding: 15px 5px;
    }

    .footer-content {
        flex-direction: column;
        gap: 5px;
    }

    .footer-section {
        width: 80%;
        margin: auto;
    }
}
.footer-bottom{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}
.social-media{
    display: flex;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}
.social-media i{
    height: 30px;
    object-fit: contain;
}
.footer-bottom p{
    font-size: 14px;
    margin-top: 10px;
    color: #d3d3d39c;
}