.home-container {
  background-color: #202336;
  padding: 20px;
  width: 100% !important;
}

.posts-container {
  margin-bottom: 20px;
  /* border: 1px solid #e5e5e5;. */
}

.no-posts-bottom {
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}

.no-posts-bottom img {
  width: 100px !important;
  object-fit: contain;
  margin-bottom: 0px !important;
}

.no-posts-bottom h2 {
  font-size: 19px !important;
}

.no-posts {
  text-align: center;
  color: #fff;
  margin-bottom: 100px;
  margin-top: 20px;
}

.no-posts h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #4d5583;
}

.no-posts img {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.no-posts p {
  font-size: 16px;
  color: #4d5583;
}

.post-bar-container {
  background-color: #e5e5e5;
  padding: 10px 10px 20px;
  border-radius: 5px;
  width: 100%;
}

.posts {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.post-holder {
  width: 40%;
  margin-top: 20px;
}

.ad-holder {
  cursor: pointer;
}

.post-username-container {
  display: flex;
  align-items: flex-start;
}

.post-content-container {
  display: flex;
  width: 90%;
  flex-direction: column;
}
.post-content-container p a {
  cursor: pointer;
}

.ad-content-container > img {
  border-radius: 8px;
}

.post-username {
  color: #000;
  font-weight: bold;
  font-size: 15px;
  margin: 0%;
  margin-left: 0% !important;
  cursor: pointer;
}

.post-content {
  color: #000;
  padding: 0% !important;
  margin: 0% !important;
  font-size: 16px;
  width: 100%;
  display: block;
  word-break: break-word;
  /* hyphens: auto; */
}

.post-line-container {
  display: flex;
  height: 5px;
  margin: 0px 5px 0px 5px;
  width: 97%;
  border-radius: 2px;
  overflow: hidden;
}

.post-line-segment {
  transition: width 1s linear;
  width: 0%;
}

.post-avatar-container {
  position: relative;
  width: 45px !important;
  height: 55px !important;
  margin-right: 5px;
  margin-top: -7.5px;
  cursor: pointer;
}

.post-avatar-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.post-liking-container {
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: auto 0;
}

.post-liking-container > p {
  font-size: 14px;
  color: #585858;
  margin: 0%;
}

.post-liking-container > .unliked-post {
  color: #7c7c7c;
}

.reply-icon {
  color: #000;
  opacity: 0;
  margin-top: 5px;
  height: 25px !important;
  width: 25px !important;
}

.post-bar-container:hover .reply-icon {
  opacity: 1;
}

.post-liking-container > svg {
  transition: transform 0.3s ease-in-out;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.post-liking-container > svg:hover {
  transform: scale(1.2);
}

.post-liking-container > .liked-post {
  color: rgb(216, 22, 22);
  fill: rgb(216, 22, 22);
}

.replies-action-container {
  display: flex;
  margin-top: 5px !important;
  width: 90%;
  margin: auto;
}

.replies-action-container > button {
  margin: auto;
  cursor: pointer;
  border-radius: 4px;
  padding: 10px 25px;
  cursor: pointer;
  font-size: 12px;
  font-weight: normal;
  color: white;
}

.reply-button {
  background-color: var(--green);
  border: none;
}

.reply-button:hover {
  background-color: var(--greenHover);
}

.show-more-button {
  background-color: var(--blue);
  border: 1px dashed rgb(57, 116, 151);
}

.show-more-button:hover {
  background-color: var(--blueHover);
}

.reply-holder-container {
  background-color: #e5e5e5;
  padding: 10px 5px 20px;
  border-radius: 5px;
  width: 90%;
  margin: auto;
  margin-top: 5px;
}

.reply-holder-container .post-content {
  font-size: 14px;
}

.reply-input-holder {
  background-color: #e5e5e5;
  padding: 10px 5px;
  border-radius: 5px;
  margin: auto;
  width: 90%;
  margin-top: 5px;
}

.input-reply-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.input-reply-container > input {
  border: none;
  background-color: transparent;
  padding: 0%;
  font-size: 15px;
}

.input-reply-container > input:focus {
  outline: none;
  border: none;
}

.reply-container .post-username {
  margin-bottom: 0% !important;
}

.send-reply-button {
  background-color: var(--green);
  border: none;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-size: 17px;
}

.send-reply-button:hover {
  background-color: var(--greenHover);
}

.input-reply-container > span {
  min-height: 20px !important;
  font-size: 14px;
}

.post-username-holder {
  display: flex;
}

.post-holder:hover .report-post {
  opacity: 1;
}

.report-post {
  height: 20px;
  width: 20px;
  opacity: 0;
  margin-left: 10px;
  cursor: pointer;
}

.swiper-container {
  aspect-ratio: 9 / 4;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  width: 40%;
  margin: auto;
}
.swiper-slide {
  overflow: hidden;
  margin: auto;
  width: 100%;
  height: 100%;
}
.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper {
  width: 100%;
  height: 100%;
}
.swiper-pagination-bullet-active {
  background: linear-gradient(
    90deg,
    var(--green) 0%,
    var(--blue) 100%
  ) !important;
}

.swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important;
}

.swiper .prev-button {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: transparent;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 999;
  margin-left: 10px;
}

.swiper .next-button {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: transparent;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 999;
  margin-right: 10px;
}

.swiper svg {
  background: linear-gradient(90deg, var(--green) 0%, var(--blue) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.swiper-button-prev,
.swiper-button-next {
  display: none !important;
}

@media (max-width: 768px) {
  .post-holder {
    width: 95%;
  }

  .ad-content-container {
    width: 80%;
  }

  .post-bar-container {
    width: 100%;
  }

  .post-username {
    font-size: 15px;
  }

  .post-content {
    font-size: 16px;
  }

  .post-line-container {
    height: 5px;
  }
  .swiper-container {
    width: 100%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1300px) {
  .post-holder {
    width: 60%;
  }
  .swiper-container {
    width: 60%;
  }
}
