.profile-container {
  padding: 0px 10px;
}

.profile-username {
  color: #ccc;
  margin: 0% !important;
  font-size: 13px;
}

.profile-info {
  margin: auto;
  width: 60%;
}
.profile-avatar-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.trigger-holder {
  margin-top: 20px;
  gap: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.public-profile-avatar-holder {
  margin-top: 40px !important;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}
.public-profile-avatar-holder .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 1;
  padding: 10px;
  background-color: rgba(232, 8, 8, 0.6);
  border-radius: 5px;
  outline: none;
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
}
.stats-holder {
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
}

.public-profile.stats-holder {
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  flex-direction: column;
  border-radius: 50%;
  background-color: #4d5583;
  padding: 5px;
  width: 100px;
  height: 100px;
}
.public-profile.stats-holder h3 {
  color: #fff;
  font-size: 15px;
  margin: 0;
}
.public-report-dropdown {
  position: absolute;
  top: 45px;
  right: 0;
  background-color: var(--blueHover);
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  width: 120px;
  z-index: 1;
  border-radius: 5px;
}
.public-report-dropdown > button {
  background-color: var(--blueHover);
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
}
.public-report-dropdown > button:hover {
  background-color: var(--blue);
}
.public-report-dropdown > button:first-child {
  border-bottom: 1px solid #fff;
  border-radius: 5px 5px 0 0;
}
.public-report-dropdown > button:last-child {
  border-radius: 0 0 5px 5px;
}

.public-report-dropdown::after {
  content: "";
  position: absolute;
  top: -4px;
  right: 10px;
  border: 5px solid transparent;
  border-bottom-color: var(--blueHover);
  border-bottom-color: var(--blueHover);
  border-top: 0;
  z-index: 1;
}
.public-report-dropdown::after:hover {
  border-bottom-color: var(--blue);
}
.public-report-dropdown
  > button:hover:first-child
  ~ .public-report-dropdown::after {
  border-bottom-color: var(--blue);
}

.points-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.profile-avatar-container {
  position: relative;
  width: 180px;
  height: 180px;
  margin-bottom: 5px;
}

.public-profile-avatar-container {
  position: relative;
  width: 150px;
  height: 150px;
  margin-bottom: 5px;
  background-color: #4d5583;
}

.public-profile-avatar-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.stats-username-holder {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.stats-username-holder > .stats {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.stats-username-holder > .username {
  font-size: 28px;
}

.stats-username-holder > .stats > p {
  font-size: 15px;
}

.profile-avatar-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.username {
  font-size: 23px;
  font-weight: bold;
  margin: 0%;
}

.stats-badge-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.stats-badge-holder > img {
  height: 50px;
  width: 50px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.stats-badge-holder > img:hover {
  transform: scale(1.1);
}

.profile-qr-code {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border: 5px solid #08dada;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.profile-qr-code:hover {
  transform: scale(1.1);
}

.profile-qr-code > svg {
  width: 100%;
  height: 100%;
}

.share-profile-page-title {
  height: 10%;
  margin-bottom: 10px;
}

.qrcode-container {
  display: flex;
  width: 95%;
  height: 90%;
  margin: 0 auto;
}

.qrcode-container > div {
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.qrcode-holder {
  width: 45%;
  background-color: #0d1333;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 5px;
  height: auto;
  color: #fff !important;
}

.qrcode-background {
  background-color: #fff;
  width: 80%;
  padding: 5px;
  margin-top: 15px;
  margin-bottom: 15px !important;
}

.qrcode-poster-holder {
  width: 45%;
  margin: auto;
  display: flex;
  background-color: #0d1333;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.qrcode-background > svg {
  width: 100%;
  height: auto;
}

.qrcode-holder > h2 {
  margin-top: 15px !important;
  margin-bottom: 10px !important;
}

.qrcode-container > div > p {
  text-align: center;
  font-size: 14px;
  margin: 0%;
}

.qrcode-poster-holder > h2 {
  color: #fff !important;
  margin-top: 5px;
  margin-bottom: 5px !important;
  margin-top: 10px !important;
}

.qrcode-container > div > h2 {
  text-align: center;
  font-size: 16px;
  margin: 0%;
  font-weight: bold;
}

.stats {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.stats p {
  font-size: 15px;
  margin: 0%;
  text-align: center;
}

.interests p {
  color: #4d5583;
}

.interests h3 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}

.interests-holder {
  display: flex;
  gap: 10px;
  overflow-x: scroll;
  scrollbar-width: none;
  margin: auto;
  -ms-overflow-style: none;
  overflow-y: hidden;
}

.interest-bubble {
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  flex: 0 0 auto;
  line-height: 2;
}

.add-interest-container input {
  background-color: var(--blue);
  border: none;
  padding: 8px 15px;
  border-radius: 20px;
  color: #1e2130;
  font-size: 14px;
  width: calc(100% - 30px);
}

.save-interest-container {
  display: flex;
  gap: 5px;
  flex: 0 0 auto;
}

.save-interest-container input {
  background-color: var(--blue);
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  width: 80%;
  line-height: 2;
}

.save-interest-container > input:focus::placeholder {
  font-weight: normal !important;
  color: #f4f4f4 !important;
}

.save-interest-container input::placeholder {
  color: #fff;
  font-weight: bold;
}

.add-new-button-holder {
  width: 40px;
  height: 40px;
  margin: auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--green);
  border-radius: 5px;
  cursor: pointer;
}

.add-new-button-holder:hover {
  background-color: var(--greenHover);
  border: 1px solid var(--greenHover);
}

.add-new-button-holder img {
  width: 60%;
  height: 60%;
  object-fit: contain;
  margin: auto;
}

.save-interest-button-holder {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.update-button {
  background-color: var(--green);
  border: none;
  padding: 12px 30px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.update-button:hover {
  background-color: var(--greenHover);
}

.posts-section {
  margin-left: 0px;
  margin-top: 40px;
  margin-bottom: 30px;
}

.section-title {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}

.user-posts-container p {
  color: #4d5583;
}

.user-posts-container > div {
  width: 100%;
}

.no-posts {
  color: #8e8e8e;
  font-size: 14px;
}

.public-profile-action-btns {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  width: 100%;
  margin: auto;
  position: relative;
}

.public-profile-action-btns > button {
  background-color: var(--blue);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  align-self: center;
}
.public-profile-action-btns > button:hover {
  background-color: var(--blueHover);
}

.public-profile-action-btns > .add-friend-btn {
  background-color: var(--blue);
}
.public-profile-action-btns > .add-friend-btn:hover {
  background-color: var(--blueHover);
}

.badge-popup {
  background-color: #202336;
  width: 50%;
  margin: auto;
  max-height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5px;
}

.qrcode-popup {
  max-height: 90% !important;
}

.close-icon-holder {
  border-radius: 5px;
}

.public-profile-badges {
  display: flex;
  width: 100%;
}
.profile-badges {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 50%;
  margin-top: 100px !important;
  margin: auto;
}

.badge-container {
  position: relative;
  display: inline-block;
}

.badge {
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  background-color: #4d5583;
  padding: 5px;
  border-radius: 5px;
}
.badge-container .badge .badge-name {
  margin-top: 10px;
}

/* .badge:hover {
  transform: scale(0.8);
} */

.badge > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.badge-tooltip {
  visibility: hidden;
  width: max-content;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 15px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.badge-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.badge-container:hover .badge-tooltip {
  visibility: visible;
  opacity: 1;
}

.badges-container-holder {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  scrollbar-width: none;
  width: 90%;
  padding: 5px 20px;
  margin: auto;
  -ms-overflow-style: none;
}

.badges-container-holder::-webkit-scrollbar {
  display: none;
}

.badge-item {
  background-color: #0d1333;
  display: flex;
  border-radius: 5px;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.badge-item:hover {
  transform: scale(1.015);
}

.badge-item-image {
  width: 17%;
  margin: 5px 2px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blueHover);
}

.earned-badge-item-image {
  filter: grayscale(0%);
  background-color: var(--green);
}

.badge-item-image > img {
  height: 40px;
  width: 40px;
  color: grey;
  filter: grayscale(100%);
}

.earned-badge-item-image > img {
  filter: grayscale(0%);
  color: #fff;
}

.badge-item-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 5px 5px;
  filter: grayscale(100%);
  color: grey;
}

.earned-badge-item-content {
  color: #fff;
  filter: grayscale(0%);
}

.earned-badge-item-content > h2 {
  font-weight: bold;
}

.earned-badge-item-content > p {
  color: #e7e7e7;
}

.badge-item-content > h2 {
  font-size: 16px;
  margin: 0%;
}

.badge-item-content > p {
  font-size: 14px;
  margin: 0%;
}

.print-qr-code-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0%;
}

.poster-qr-code-container {
  width: 100%;
  height: 100%;
  padding: 0%;
  position: relative;
}
.poster-qr-code-image-black-front {
  background-color: #000;
  bottom: 0;
  opacity: 0.5;
  width: 100%;
  height: 30%;
  position: absolute;
  z-index: 2;
}
.poster-qr-code-container > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.preview-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.print-qr-code-background {
  height: 80%;
  width: 100%;
}

.print-qr-code-background > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.preview-poster-qr-code-holder > svg {
  width: 50px;
  height: 50px;
  margin: auto;
}

.poster-qr-code-holder {
  position: absolute;
  /* border: 2px dashed #fff; */
  border-radius: 5px;
  z-index: 3;
  right: 0;
  bottom: 0;
  display: flex;
  width: 40%;
  margin-right: 5px;
  margin-bottom: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.previewcornerlogo {
  bottom: 0;
  left: 0;
  position: absolute;

  object-fit: contain;
  max-width: 150px;
  max-height: 36px;
  opacity: 1;
  z-index: 50;
  margin: 10px;
}

.cornerlogo {
  bottom: 0;
  left: 0;
  position: absolute;

  object-fit: contain;
  max-width: 300px;
  max-height: 71px;
  opacity: 1;
  z-index: 50;
  margin: 10px;
}

.poster-qr-code-holder > svg {
  width: 200px;
  height: 200px;
  margin: auto;
}

.poster-qr-code-holder > p {
  font-size: 13px;
  color: #fff !important;
  text-decoration: underline !important;
  margin-top: 15px !important;
  margin: 0%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: center;
}

.poster-qr-code-holder > a:hover {
  color: #fff !important;
  text-decoration: none;
}

.print-qr-code-holder {
  width: 100%;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  height: 18%;
  margin-top: 10px;
  align-items: center;
  margin-bottom: 10px;
}
.print-qr-code-holder > svg {
  width: 20%;
}
.print-qr-code-holder > img {
  width: 40% !important;
  object-fit: contain;
}

.poster-container {
  width: 80%;
  margin: auto;
  height: 70%;
  margin-bottom: 15px;
  border: 1px solid #bebebe;
  padding: 2px;
}

.print-button {
  background-color: var(--green);
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 40px;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  margin: auto;
  margin-bottom: 20px !important;
  align-self: center;
}

.print-button:hover {
  background-color: var(--greenHover);
}
.profile-bio,
.profile-rating,
.icebreakers-holder {
  margin-bottom: 20px;
  max-width: 100%;
}

.profile-bio p {
  color: #4d5583;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
}
.icebreakers-holder p {
  color: #4d5583;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
}

.bio-holder {
  position: relative;
  display: flex;
  border: 1px solid #bebebe;
  border-radius: 5px;
  background-color: #fff;
  max-height: 80px;
}
.line-separator {
  width: 100%;
  height: 2px;
  display: block;
  background-color: #bebebe;
  margin: auto;
  margin-top: 25px;
}
.bio-holder textarea {
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 14px;
  resize: none;
}

.icebreaker-container {
  display: flex;
  gap: 10px;
  position: relative;
  margin-top: 10px;
  background-color: white;
  border: 1px solid #bebebe;
  border-radius: 5px;
}
.icebreaker-container > input {
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 14px;
}
.icebreaker-container > button {
  background-color: var(--green);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  top: 5px;
  right: 10px;

  padding: 5px 10px;
}
.bio-holder textarea:focus {
  outline: none;
  border: none;
}
. .bio-holder textarea::placeholder {
  color: #fff;
  font-weight: bold;
}
.bio-holder button {
  background-color: var(--green);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  margin-left: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.mutual-friend-avatar {
  position: relative;
  width: 150px;
  height: 150px;
  /* margin-bottom: 5px; */
}
.mutual-friend {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}
.mutual-friend p {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.mutual-friend-avatar img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media screen and (max-width: 767px) {
  .interests {
    margin: 5px 0%;
  }
  .profile-info {
    width: 100%;
  }
  .posts-section {
    margin: 5px 0%;
  }
  .user-posts-container {
    padding-left: 10px;
  }
  .user-posts-container > div {
    width: 95%;
  }
  .public-profile-action-btns {
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
  }
  .public-profile-avatar-holder {
    width: 100%;
  }
  .stats-username-holder > .stats {
    flex-direction: row;
    gap: 5px;
  }
  .public-profile.stats-holder {
    width: 80px;
    height: 80px;
    padding: 2px;
  }
  .public-profile-avatar-container {
    width: 120px;
    height: 120px;
  }
  .badge-popup {
    width: 95%;
  }
  .badges-container-holder {
    width: 100%;
    padding: 5px;
  }
  .badge-item-image {
    width: 25%;
  }
  .badge-item-image > svg {
    width: 30px;
    height: 30px;
  }
  .qrcode-container {
    flex-wrap: wrap;
  }

  .qrcode-poster-holder {
    display: none !important;
  }

  .poster-container {
    margin: 0% !important;
    margin-top: 15px !important;
  }

  .share-profile-page-title {
    height: 10% !important;
    margin-bottom: 10px !important;
  }

  .qrcode-container {
    height: 90% !important;
  }

  .qrcode-holder {
    width: 100% !important;
    margin: 0% !important;
    font-size: 14px !important;
    height: 80%;
  }

  .qrcode-holder > h2 {
    margin-bottom: 5px !important;
    margin-top: 0% !important;
  }

  .qrcode-holder > svg {
    margin: 0% !important;
    width: 70%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1300px) {
  .public-profile-action-btns {
    width: 100%;
  }
  .qrcode-holder > svg {
    margin: 0% !important;
  }
}

@media print {
  .print-only {
    display: block !important;
  }

  .poster-qr-code-container {
    width: 100%;
    height: 100vh;
    page-break-inside: avoid;
    position: relative;
  }

  .poster-qr-code-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen {
  .print-only {
    display: none;
  }
}

.user-status {
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
  border-radius: 50%;
  right: 20px;
}
.user-status.offline {
  background: transparent;
}
.user-status.online {
  background-color: green;
}
