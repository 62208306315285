
.onboarding-container {
    width: 100%;
    margin: 0 auto;
    padding: 5px;
    max-width:800px;
  }
  .onboarding-container > h1{
    text-align: center;
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 70px;
  }
  .onboarding-container > p{
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
  }

  .interests{
    width: 100%;
    margin: 20px auto;
  }
  .languages{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 60px auto;
    margin-bottom: 100px;
  }
  .languages>div{
    gap: 20px;
  }
  .languages>div>div{
    padding: 8px 25px;
  }
  .change-these{
    font-size: 16px !important;
    color: #fff;
    text-align: center;
  }
  .continue-button {
    padding: 10px 30px;
    font-size: 16px;
    background-color: var(--green);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none !important;
  }
  
  .continue-button:hover {
    background-color: var(--greenHover);
    color: #fff;
  }

  .profile-onboarding-container{
    display: flex;
    flex-direction: column;
    max-width: 800px; 
    margin: auto;
  }

  .bear-container{
    display: flex;
    flex-direction: column;
    width: 30%;
    margin: auto;
  }

  .bear-container>img{
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
  }

  .onboarding-description{
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 17px;
    text-align: center;
  }

  .onboarding-title{
    margin-top: 30px;
    font-size: 22px;
    margin-bottom: 20px;
    text-align: center;
  }

  .add-interest-container{
    background-color: rgb(0, 187, 0, 0.8);
  }

  .remove-interest-container, .add-interest-container{
    display: none;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
  }

  .remove-interest-container{
    background-color:rgba(220,20,60, 0.8);
  }

  .remove-interest-container>svg, .add-interest-container>svg{
    height: 60%;
    width: 60%;
  }

  .interest-container{
    display: flex;
    position: relative;
  }

  .interest-container:hover .display-box{
    display: flex;
  }

  .onboarding-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top:5px;
  }
  .onboarding-header .skip{
    color: #24ffff;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    text-decoration:underline;
  }
  @media screen and (max-width: 767px) {
    .languages{
      width: 100%;
    }
    .languages>div{
      gap: 10px;
    }
  }