
.section-line-segment {
  width: 50%;
  margin: 2% auto;
  height: 2px;
  border-radius: 12px;
  background-color: white;
}

#nav .bar {
  background-color: #08dada !important;
}
#nav button{
  color: var(--em-rgb-accent) !important;
}
.settings-container {
  /* marginLeft: "auto", marginRight: "auto", fontSize: "20px", paddingBottom: "40px"; */
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.settings-content {
  display: flex;
  flex-direction: column;
  padding: 0% 2%;
}

.account-logout-section {
  border-radius: 8px;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.account-logout-section > h2 {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
}

.logout-section {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.logout-section > h3 {
  font-size: 15px;
  align-self: flex-start;
}

.settings-section {
  width: 100%;
  margin: 2px auto;
}

.settings-section h2 {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: bold;
}


/* .toolbar button:hover,
.toolbar label:hover {
  background-color: #e0e0e0;
} */

.toolbar input[type="file"] {
  display: none;
}

.logout-button {
  background-color: var(--blue);
  color: white;
  border: none;
  margin-top: 30px;
  border-radius: 6px;
  padding: 12px 30px;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  align-self: center;
}
.logout-button:hover {
  background-color: var(--blueHover);
}

.legal-item {
  margin-bottom: 20px;
  margin-left: 5px;
}

.legal-item h3 {
  font-size: 14px;
  margin-bottom: 5px;
}

.delete-account-btn {
  background-color: rgb(210, 20, 20);
  color: #fff;
  padding: 15px 12px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  margin: 1% 0%;
  font-size: 15px;
  width: 100%;
  border: none;
}

.delete-account-btn:hover {
  background-color: rgb(169, 16, 16);
}

.open-link-holder {
  background-color: #0d1333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px;
  border-radius: 8px;
  cursor: pointer;
  margin: 1% 0%;
}

.open-link-holder p {
  margin: 0;
}

.open-link-holder > img {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.legal-item p {
  font-size: 14px;
  line-height: 1.5;
}


@media screen and (max-width: 767px) {
  .account-logout-section {
    margin-left: 0%;
  }
  .logout-section {
    gap: 40px;
  }
  .section-line-segment {
    width: 80%;
  }
  .settings-section {
    width: 100%;
  }
}
.username-change-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 12px 12px; */
  border-radius: 8px;
  cursor: pointer;
  margin: 1% 0%;
}
.username-change-holder input {
  border: none;
  background-color: #0d1333;
  color: white;
  font-size: 16px;
  width: 80%;
  padding: 10px 10px;
}

.change-username-btn {
  background-color: var(--blue);
  color: white;
  padding: 10px 12px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  width: 20%;
  justify-content: center;
  display: flex;
  margin-left: 10px;
}
.change-username-btn.large{
  display: flex;
}
.change-username-btn.small{
  display: none;
}

@media screen and (max-width: 567px) {
  .username-change-holder{
    flex-direction: column;
  }
  .username-change-holder input {
    width: 100%;
  }
.change-username-btn {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
  .change-username-btn.large{
    display: none;
  }
  .change-username-btn.small{
    display: block;
  }
}