.page-not-found-container {
    width: 100%;
    display: flex;
    padding: 20px;
  }
.page-not-found-content{
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;
}

.page-not-found-content-description{
    width: 90%;
    margin-left: 10%;
}

.page-not-found-content-description>h1{
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 20px;
}

.error-line-segment{
    width: 60%;
    height: 2px;
    margin-top: 1%;
    border-radius: 12px;
    background-color: white;
    margin-bottom: 40px;
}

.error-code{
    font-size: 18px;
    margin: 0%;
}

.error-description{
    font-size: 16px;
}

.page-not-found-image{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-not-found-image>img{
    width: 90%;
}

@media (max-width: 768px) {
    .page-not-found-container{
        flex-direction: column;
        gap: 20px;
        padding: 5px;
        margin-top: 20px;
    }
    .page-not-found-image{
        width: 100%;
    }
    .page-not-found-content{
        width: 100%;
    }
    .page-not-found-content-description{
        margin: auto;
        width: 90%;
    }
    .page-not-found-content-description>h1{
        font-size: 30px;
    }
    .page-not-found-image>img{
        width: 40%; 
    }
    
}