.about-container {
    padding: 5px;
  }
  
  .about-section {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    margin-bottom: 40px;
    height: auto;
  }
  
  .about-section-image {
    flex: 1;
    max-width: 45%;
    height: 100%;
    height: 300px;
  }
  
  .about-section-image img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .about-section-description {
    flex: 1;
    max-width: 50%;
  }
  
  .about-section-description>h2 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .about-section-description>p {
    font-size: 16px;
    line-height: 1.6;
    font-weight: normal;
  }
  
  .section-line-segment {
    height: 2px;
    width: 90%;
    background-color: #fff;
    margin: 40px auto;
  }

  @media (max-width: 768px) {
    .about-section {
      flex-direction: column;
      height: auto;
    }
  
    .about-section-image,
    .about-section-description {
      max-width: 100%;
    }
  
    .about-section-image {
      margin-bottom: 20px;
    }
  }